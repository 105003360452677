<template>
  <div>
    <dialog-info ref="dialogInfo" :info="info" :action="handlePage"/>
  </div>
</template>

<script>
import DialogInfo from '@/components/common/DialogInfo'
import {
  REFERENCE_LIST,
  PROGRAM_STUDY,
  FORM_LIST
} from '@/constants/pages'
  export default {
    data() {
      return {
        info: {
          title: 'dialog.not.found',
          desc: 'dialog.not.found.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    components: {
      DialogInfo
    },
    mounted() {
      this.$refs.dialogInfo && this.$refs.dialogInfo.show()
    },
    methods: {
      handlePage() {
        const config = JSON.parse(localStorage.getItem('feConfig'))
        if (config.role === 'mahasiswa') {
          this.$router.replace({
            name: PROGRAM_STUDY
          }).catch(() => {})
        } else if (config.role === 'dosen') {
          this.$router.replace({
            name: FORM_LIST
          }).catch(() => {})
        } else {
          this.$router.replace({
            name: REFERENCE_LIST
          }).catch(() => {})
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>