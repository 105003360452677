<template>
  <v-dialog
    v-model="dialog"
    max-width="550"
    @click:outside="handleDialog()"
  >
    <v-card>
      <v-card-title class="text-h6">
        {{ info.title | text }}
      </v-card-title>

      <v-card-text>
        {{ info.desc | text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="handleDialog()"
        >
          {{ info.btn | text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogInfo',
    props: {
      info: {
        required: true,
        type: Object
      },
      action: {
        type: Function,
        default: () => {}
      }
    },
    data () {
      return {
        dialog: false,
        isHandleDialog: true
      }
    },
    methods: {
      show () {
        this.dialog = true
        setTimeout(() => {
          this.isHandleDialog && this.handleDialog()
        }, 5000)
      },
      hide () {
        this.dialog = false
      },
      handleDialog() {
        this.hide()
        this.action()
        this.isHandleDialog = false
      }
    }
  }
</script>
